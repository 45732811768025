import { v4 as uuid } from "uuid"
import { TGraphStyle } from "../types"
import {
  decision,
  input,
  start,
  process,
} from "../../components/graph/nodes/Flowchart"
import { Arrow } from "../../components/graph/nodes"

export const flowChartInit: TGraphStyle[] = [
  {
    id: uuid(),
    type: "flowchart",
    node: "start",
    style: start,
  },
  {
    id: uuid(),
    type: "flowchart",
    node: "input",
    style: input,
  },
  {
    id: uuid(),
    type: "flowchart",
    node: "process",
    style: process,
  },
  {
    id: uuid(),
    type: "flowchart",
    node: "decision",
    style: decision,
  },
  {
    id: uuid(),
    type: "flowchart",
    node: "arrow",
    style: Arrow,
  },
]
