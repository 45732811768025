import useLiveQuery from "../hooks/use-live-query"
import { DexieDao } from "./IDao"
import { GraphType, TGraphStyle } from "./types"

export default class GraphStyle extends DexieDao<TGraphStyle> {
  add(graph: TGraphStyle): void {
    this.dbRef.graphStyle.add(graph)
  }

  bulkAdd(graphs: TGraphStyle[]): void {
    this.dbRef.graphStyle.bulkAdd(graphs)
  }

  edit(id: string, graph: TGraphStyle): void {
    this.dbRef.graphStyle.update(id, graph)
  }

  async get(id: string): Promise<TGraphStyle> {
    const node = await this.dbRef.graphStyle.where("id").equals(id).toArray()
    return node[0]
  }

  getNodes(type: GraphType): TGraphStyle[] {
    return useLiveQuery(
      this.dbRef.graphStyle,
      () => this.dbRef.graphStyle.where("type").equals(type).toArray(),
      [type],
    )
  }

  getList(): TGraphStyle[] {
    return []
  }

  delete() {}
}
