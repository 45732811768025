import { useRef } from "react"
import DexieDB from "dexie"
import config from "../config"
import { DBSchema, schema } from "./types"
import FolderDao from "./Folder"
import FileDao from "./File"
import GraphStyle from "./GraphStyle"
import ProjectDao from "./Project"

export default function useIndexedDb() {
  const dbRef = useRef<DBSchema>(
    // @ts-ignore
    new DexieDB(config.DB_NAME).version(config.DB_VERSION).stores(schema)
      .db as DBSchema,
  ).current

  const projectDao = new ProjectDao(dbRef)
  const folderDao = new FolderDao(dbRef)
  const fileDao = new FileDao(dbRef)
  const graphStyleDao = new GraphStyle(dbRef)

  return {
    projectDao,
    folderDao,
    fileDao,
    graphStyleDao,
  }
}
