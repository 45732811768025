import React from "react"
import useIndexedDb from "../db/use-indexed-db"
import ProjectDao from "../db/Project"
import FileDao from "../db/File"
import FolderDao from "../db/Folder"
import GraphStyle from "../db/GraphStyle"
import { DBSchema } from "../db/types"
import useLocalStorage, { initialStorage, StorageDao } from "../db/use-local-storage"
import useDatabaseSeeding, { DBManger } from "../db/use-db-seeding"

const initSchema = {} as DBSchema

export const DBContext = React.createContext<DBManger>({
  projectDao: new ProjectDao(initSchema),
  folderDao: new FolderDao(initSchema),
  fileDao: new FileDao(initSchema),
  graphStyleDao: new GraphStyle(initSchema),
  storageMap: initialStorage,
  selectedFileDao: new StorageDao("selected-element-id", () => ""),
  expandEditorDao: new StorageDao("expand-editor", () => ""),
})

export const DBProvider: React.FC = ({ children }) => {
  const dbManger = useIndexedDb()
  const storageManger = useLocalStorage()

  useDatabaseSeeding({ ...dbManger, ...storageManger })

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DBContext.Provider value={{ ...dbManger, ...storageManger }}>
      {children}
    </DBContext.Provider>
  )
}
