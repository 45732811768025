import { useCallback, useEffect, useState } from "react"

type StorageUpdateCallback = () => void
type StorageKeys = "selected-element-id" | "expand-editor"

export type StorageMap<T> = { [key in StorageKeys]: T }

export class StorageDao<T> {
  key: StorageKeys

  protected updateStorage: StorageUpdateCallback

  constructor(key: StorageKeys, updateStorage: StorageUpdateCallback) {
    this.key = key
    this.updateStorage = updateStorage
  }

  set(value: T) {
    localStorage.setItem(this.key, this.getValue(value))
    this.updateStorage()
  }

  get() {
    return StorageDao.parseValue<T>(localStorage.getItem(this.key) as T)
  }

  remove() {
    localStorage.removeItem(this.key)
    this.updateStorage()
  }

  private getValue(value: T) {
    if (value === "true" || value === "false") {
      return String(value)
    }

    return value as string
  }

  static parseValue<T>(value: T) {
    if (value === "true" || value === "false") {
      return JSON.parse(value as string)
    }

    return value
  }

  static get<T>(key: StorageKeys): T {
    return this.parseValue(localStorage.getItem(key))
  }
}

export const initialStorage: StorageMap<string | boolean | undefined> = {
  "selected-element-id": undefined,
  "expand-editor": undefined,
}

export default function useLocalStorage() {
  const [storageMap, setStorageMap] = useState(initialStorage)

  const updateStorage = useCallback(() => {
    setStorageMap({
      "selected-element-id": StorageDao.get("selected-element-id"),
      "expand-editor": StorageDao.get("expand-editor"),
    })
  }, [])

  const selectedFileDao = new StorageDao<string>(
    "selected-element-id",
    updateStorage,
  )
  const expandEditorDao = new StorageDao<boolean>("expand-editor", updateStorage)

  useEffect(() => {
    setStorageMap({
      "selected-element-id": selectedFileDao.get(),
      "expand-editor": expandEditorDao.get(),
    })
  }, [])

  return { storageMap, selectedFileDao, expandEditorDao }
}
