const circle = {
  r: 4,
  x: -50,
  y: -50,
  magnet: true,
  stroke: "#5F95FF",
  strokeWidth: 1,
  fill: "#fff",
  style: {
    visibility: "hidden",
  },
}

const groups = [
  {
    group: "top",
  },
  {
    group: "right",
  },
  {
    group: "bottom",
  },
  {
    group: "left",
  },
]

export const ports = {
  groups: {
    top: {
      position: "top",
      attrs: { circle },
    },
    right: {
      position: "right",
      attrs: { circle },
    },
    bottom: {
      position: "bottom",
      attrs: { circle },
    },
    left: {
      position: "left",
      attrs: { circle },
    },
  },
  items: groups,
}

export const rhombusPorts = {
  groups: {
    top: {
      position: "top",
      attrs: { circle },
    },
    right: {
      position: {
        name: "absolute",
        args: { x: "14%", y: "50%" },
      },
      attrs: {
        circle,
      },
    },
    bottom: {
      position: "bottom",
      attrs: { circle },
    },
    left: {
      position: {
        name: "absolute",
        args: { x: "87%", y: "50%" },
      },
      attrs: { circle },
    },
  },
  items: groups,
}
