/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// eslint-disable-next-line import/extensions,import/no-unresolved
import "bootstrap/dist/js/bootstrap.min.js"

import React from "react"
import { DBProvider } from "./src/provider/DBProvider"

// eslint-disable-next-line import/prefer-default-export,react/jsx-filename-extension
export const wrapRootElement = ({ element }) => <DBProvider>{element}</DBProvider>
