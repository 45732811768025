import useLiveQuery from "../hooks/use-live-query"
import { DexieDao } from "./IDao"
import { TFolder } from "./types"

export default class FolderDao extends DexieDao<TFolder> {
  add(folder: TFolder): void {
    this.dbRef.folder.add(folder)
  }

  edit(id: string, folder: TFolder): void {
    this.dbRef.folder.update(id, folder)
  }

  delete(folderId: string) {
    this.dbRef.folder.delete(folderId)
  }

  bulkDelete(keys: string[]) {
    this.dbRef.folder.bulkDelete(keys)
  }

  async get(id: string): Promise<TFolder> {
    const folders = await this.dbRef.folder.where("id").equals(id).toArray()
    return folders[0]
  }

  getList(): TFolder[] {
    return (
      useLiveQuery(this.dbRef.folder, () => this.dbRef.folder.toArray(), []) || []
    )
  }
}
