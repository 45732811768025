import { Cell } from "@antv/x6"

const LOGIC_GATE_AND = "logic_gate_and"
const LOGIC_GATE_NAND = "logic_gate_nand"
const LOGIC_GATE_OR = "logic_gate_or"
const LOGIC_GATE_NOR = "logic_gate_nor"
const LOGIC_GATE_XNOR = "logic_gate_xnor"
const LOGIC_GATE_XOR = "logic_gate_xor"
const LOGIC_GATE_NOT = "logic_gate_not"
const LOGIC_GATE_BUFFER = "logic_gate_buffer"
const LOGIC_GATE_LABEL = "logic_gate_label"

const tools = [
  {
    name: "button-remove",
    args: {
      x: "90%",
      y: "-20%",
    },
  },
]

const logicGateCircle = {
  r: 5,
  x: -50,
  y: -50,
  magnet: true,
  stroke: "#000",
  strokeWidth: 1,
  fill: "#fff",
  style: {
    visibility: "hidden",
  },
}

const getPorts = (position: [number, number]) => ({
  ...logicGatePorts,
  groups: {
    ...logicGatePorts.groups,
    out: {
      ...logicGatePorts.groups.out,
      position,
    },
  },
})

const logicGatePorts = {
  groups: {
    in: {
      attrs: {
        circle: logicGateCircle,
      },
    },
    out: {
      attrs: {
        circle: logicGateCircle,
      },
    },
  },
  items: [
    { id: "1", group: "in" },
    { id: "2", group: "in" },
    { id: "output", group: "out" },
  ],
}

const AND: Partial<Cell.Metadata> = {
  shape: LOGIC_GATE_AND,
  width: 70,
  height: 30,
  tools,
  attrs: {
    image: {
      "xlink:href":
        "data:image/svg+xml,%3Csvg width='52' height='27' viewBox='0 0 52 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M41.5 13.5C41.5 6.324 35.676 0.5 28.5 0.5L9.5 0.502001V26.5H28.5C35.676 26.5 41.5 20.676 41.5 13.5ZM41.5 13.5H50.556M0.5 7H9.5M0.5 20H9.5' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E%0A",
    },
  },
  ports: getPorts([56, 14]),
}

const NAND: Partial<Cell.Metadata> = {
  shape: LOGIC_GATE_NAND,
  width: 70,
  height: 30,
  tools,
  attrs: {
    image: {
      "xlink:href":
        "data:image/svg+xml,%3Csvg width='55' height='27' viewBox='0 0 55 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M41.5 13.5C41.5 6.324 35.68 0.5 28.5 0.5L9.5 0.502001V26.5H28.5C35.68 26.5 41.5 20.676 41.5 13.5ZM41.5 13.5C41.5 14.604 42.4 15.5 43.5 15.5C44.6 15.5 45.5 14.604 45.5 13.5M41.5 13.5C41.5 12.396 42.4 11.5 43.5 11.5C44.6 11.5 45.5 12.396 45.5 13.5M45.5 13.5H54.5M0.5 7H9.5M0.5 20H9.5' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E%0A",
    },
  },
  ports: getPorts([58, 14]),
}

const OR: Partial<Cell.Metadata> = {
  shape: LOGIC_GATE_OR,
  width: 70,
  height: 30,
  tools,
  attrs: {
    image: {
      "xlink:href":
        "data:image/svg+xml,%3Csvg width='53' height='27' viewBox='0 0 53 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M43.5 13.501H52.5M1 7.00098H10M1 20.001H10M7.5 0.500977V0.562973C9.699 4.37797 10.969 8.78398 10.969 13.501C10.969 18.217 9.699 22.623 7.5 26.436V26.496H20.969C30.579 26.496 38.969 21.261 43.469 13.501C38.969 5.73998 30.579 0.500977 20.969 0.500977H7.5Z' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E%0A",
    },
  },
  ports: getPorts([56, 14]),
}

const NOR: Partial<Cell.Metadata> = {
  shape: LOGIC_GATE_NOR,
  width: 70,
  height: 30,
  tools,
  attrs: {
    image: {
      "xlink:href":
        "data:image/svg+xml,%3Csvg width='57' height='27' viewBox='0 0 57 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.5 13.501C47.5 14.605 46.6 15.501 45.5 15.501C44.4 15.501 43.5 14.605 43.5 13.501C43.5 12.397 44.4 11.501 45.5 11.501C46.6 11.501 47.5 12.397 47.5 13.501ZM47.5 13.501L56.5 13.5M1 7H10M1 20H10M7.5 0.5V0.562996C9.699 4.378 10.969 8.784 10.969 13.5C10.969 18.217 9.699 22.623 7.5 26.436V26.496H20.97C30.58 26.496 38.97 21.261 43.47 13.5C38.97 5.74 30.58 0.5 20.97 0.5H7.5Z' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E%0A",
    },
  },
  ports: getPorts([62, 14]),
}

const XOR: Partial<Cell.Metadata> = {
  shape: LOGIC_GATE_XOR,
  width: 70,
  height: 30,
  tools,
  attrs: {
    image: {
      "xlink:href":
        "data:image/svg+xml,%3Csvg width='58' height='29' viewBox='0 0 58 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 1.56598C9.699 5.37598 10.969 9.78597 10.969 14.496C10.969 19.216 9.699 23.626 7.5 27.436M48.5 14.496H57.5M1 7.99597H10M1 20.996H10M12.5 1.49597V1.56598C14.699 5.37598 15.969 9.78597 15.969 14.496C15.969 19.216 14.699 23.626 12.5 27.436V27.496H25.969C35.579 27.496 43.969 22.266 48.469 14.496C43.969 6.73597 35.579 1.49597 25.969 1.49597H12.5Z' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E%0A",
    },
  },
  ports: getPorts([62, 14]),
}

const XNOR: Partial<Cell.Metadata> = {
  shape: LOGIC_GATE_XNOR,
  width: 70,
  height: 30,
  tools,
  attrs: {
    image: {
      "xlink:href":
        "data:image/svg+xml,%3Csvg width='62' height='29' viewBox='0 0 62 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 1.56598C9.699 5.37598 10.969 9.78597 10.969 14.496C10.969 19.216 9.699 23.626 7.5 27.436M52.5 14.496H61.5M1 7.99597H10M1 20.996H10M12.5 1.49597V1.56598C14.7 5.37598 15.97 9.78597 15.97 14.496C15.97 19.216 14.7 23.626 12.5 27.436V27.496H25.97C35.58 27.496 43.97 22.266 48.47 14.496C43.97 6.73597 35.58 1.49597 25.97 1.49597H12.5ZM52.5 14.506C52.5 15.606 51.6 16.506 50.5 16.506C49.4 16.506 48.5 15.606 48.5 14.506C48.5 13.396 49.4 12.506 50.5 12.506C51.6 12.506 52.5 13.396 52.5 14.506Z' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E%0A",
    },
  },
  ports: getPorts([64, 14]),
}

const NOT: Partial<Cell.Metadata> = {
  shape: LOGIC_GATE_NOT,
  width: 70,
  height: 30,
  tools,
  attrs: {
    image: {
      "xlink:href":
        "data:image/svg+xml,%3Csvg width='43' height='24' viewBox='0 0 43 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33 12.496C33 13.606 32.104 14.496 31 14.496C29.896 14.496 29 13.606 29 12.496C29 11.396 29.896 10.496 31 10.496C32.104 10.496 33 11.396 33 12.496ZM33 12.496H42M0.5 12.496H9.5M9.5 23.496V1.49597L28.486 12.496L9.5 23.496Z' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E%0A",
    },
  },
  ports: {
    groups: {
      ...logicGatePorts.groups,
      in: {
        ...logicGatePorts.groups.out,
        position: [-1, 12],
      },
      out: {
        ...logicGatePorts.groups.out,
        position: [48, 12],
      },
    },
    items: [
      { id: "1", group: "in" },
      { id: "output", group: "out" },
    ],
  },
}

const Buffer: Partial<Cell.Metadata> = {
  shape: LOGIC_GATE_BUFFER,
  width: 70,
  height: 30,
  tools,
  attrs: {
    image: {
      "xlink:href":
        "data:image/svg+xml,%3Csvg width='40' height='24' viewBox='0 0 40 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 12H10M29 12H38H39.5M10 23V1L28.986 12L10 23Z' stroke='black' stroke-linecap='square'/%3E%3C/svg%3E%0A",
    },
  },
  ports: {
    groups: {
      ...logicGatePorts.groups,
      in: {
        ...logicGatePorts.groups.out,
        position: [-1, 12],
      },
      out: {
        ...logicGatePorts.groups.out,
        position: [42, 12],
      },
    },
    items: [
      { id: "1", group: "in" },
      { id: "output", group: "out" },
    ],
  },
}

const Label: Partial<Cell.Metadata> = {
  shape: LOGIC_GATE_LABEL,
  width: 70,
  height: 30,
  tools,
  label: "label",
  attrs: {
    body: {
      strokeWidth: 0,
    },
  },
  ports: {
    groups: {
      out: {
        ...logicGatePorts.groups.out,
        position: "bottom",
      },
    },
    items: [{ id: "output", group: "out" }],
  },
}

export {
  AND,
  NAND,
  OR,
  NOR,
  XNOR,
  XOR,
  NOT,
  Buffer,
  Label,
  LOGIC_GATE_AND,
  LOGIC_GATE_NAND,
  LOGIC_GATE_OR,
  LOGIC_GATE_NOR,
  LOGIC_GATE_XNOR,
  LOGIC_GATE_XOR,
  LOGIC_GATE_NOT,
  LOGIC_GATE_BUFFER,
  LOGIC_GATE_LABEL,
}
