import { TFile, TFolder, TProject } from "../types"

export const INIT_FILE_CONTENT =
  "\\documentclass{article}\n\\title{\\LaTeX. Showcase}\n\\author{made with $\\varheartsuit$ by Author Name}\n\\date{2022--2023}\n\n\n\\begin{document}\n\n\\maketitle\n\n\n\\begin{abstract}\nThis document will show most of the features of \\LaTeX, \nwhile at the same time being a gentle introduction to \\LaTeX.\n\\end{abstract}\n\n\\end{document}"

// TODO:: expand files to include Images & CLS & Bibliography file

export const projectInit: TProject = {
  id: "init-1-project",
  name: "First project",
}

export const foldersInit: TFolder[] = [
  {
    id: "init-2-file",
    type: "file",
    name: "main.tex",
    projectId: "init-1-project",
  },
]

export const filesInit: TFile[] = [
  {
    id: "init-2-file",
    content: INIT_FILE_CONTENT,
    contentType: "tex",
  },
]
