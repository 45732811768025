import { Cell, Graph } from "@antv/x6"
import { RECT, POLYGON, RHOMBUS, ROUNDED_REACT } from "./Flowchart"
import { ports, rhombusPorts } from "./Ports"
import {
  LOGIC_GATE_AND,
  LOGIC_GATE_NAND,
  LOGIC_GATE_BUFFER,
  LOGIC_GATE_NOR,
  LOGIC_GATE_NOT,
  LOGIC_GATE_OR,
  LOGIC_GATE_XNOR,
  LOGIC_GATE_XOR,
  LOGIC_GATE_LABEL,
} from "./Logic-gate"

const Image = {
  inherit: "rect",
  markup: [{ tagName: "image" }],
}

const registerNodes = () => {
  /** Flowchart Nodes */

  Graph.registerNode(
    RECT,
    {
      inherit: "rect",
      ports: {
        ...ports,
      },
    },
    true,
  )

  Graph.registerNode(
    ROUNDED_REACT,
    {
      inherit: "rect",
      ports: {
        ...ports,
      },
    },
    true,
  )

  Graph.registerNode(
    POLYGON,
    {
      inherit: "polygon",
      ports: {
        ...ports,
      },
    },
    true,
  )

  // @ts-ignore
  Graph.registerNode(
    RHOMBUS,
    {
      inherit: "polygon",
      ports: rhombusPorts,
    },
    true,
  )

  /** Logic gate Nodes */

  Graph.registerNode(LOGIC_GATE_AND, Image, true)

  Graph.registerNode(LOGIC_GATE_NAND, Image, true)

  Graph.registerNode(LOGIC_GATE_OR, Image, true)

  Graph.registerNode(LOGIC_GATE_NOR, Image, true)

  Graph.registerNode(LOGIC_GATE_XNOR, Image, true)

  Graph.registerNode(LOGIC_GATE_XOR, Image, true)

  Graph.registerNode(LOGIC_GATE_NOT, Image, true)

  Graph.registerNode(LOGIC_GATE_BUFFER, Image, true)

  Graph.registerNode(LOGIC_GATE_LABEL, { inherit: "rect" }, true)
}

const Arrow: Partial<Cell.Metadata> = {
  shape: "edge",
  attrs: {
    line: {
      stroke: "#a2b1c3",
      strokeWidth: 2,
      targetMarker: {
        name: "block",
        width: 12,
        height: 8,
      },
    },
  },
}

const LineArrow: Partial<Cell.Metadata> = {
  shape: "edge",
  attrs: {
    line: {
      strokeWidth: 2,
      targetMarker: null,
    },
  },
}

export default registerNodes

export { Arrow, LineArrow }
