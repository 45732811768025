import { useEffect } from "react"
import { flowChartInit } from "./seeding-data/init-graph"
import { filesInit, foldersInit } from "./seeding-data/init-files"
import FolderDao from "./Folder"
import FileDao from "./File"
import GraphStyle from "./GraphStyle"
import { StorageDao, StorageMap } from "./use-local-storage"
import ProjectDao from "./Project"

export interface DBManger {
  projectDao: ProjectDao
  folderDao: FolderDao
  fileDao: FileDao
  graphStyleDao: GraphStyle
  storageMap: StorageMap<unknown>
  selectedFileDao: StorageDao<string>
  expandEditorDao: StorageDao<boolean>
}

export default (dbManger: DBManger) => {
  useEffect(() => {
    const { graphStyleDao, folderDao, fileDao, selectedFileDao } = dbManger

    ;(async () => {
      const folders = await folderDao.dbRef.folder.toArray()
      if (folders.length < 1) {
        foldersInit.map((folder) => folderDao.add(folder))
        filesInit.map((file) => {
          fileDao.add(file)
          if (file.contentType === "tex") selectedFileDao.set(file.id)
        })
      }

      const nodes = await graphStyleDao.dbRef.graphStyle
        .where("type")
        .equals("flowchart")
        .toArray()
      if (nodes.length < 1) {
        graphStyleDao.bulkAdd(flowChartInit)
      }
      // TODO:: add seed for other graphs type
    })()
  }, [])
}
