import useLiveQuery from "../hooks/use-live-query"
import { DexieDao } from "./IDao"
import { TFile } from "./types"

export default class FileDao extends DexieDao<TFile> {
  add(file: TFile): void {
    this.dbRef.file.add(file)
  }

  edit(id: string, file: TFile): void {
    this.dbRef.file.update(id, file)
  }

  async delete(id: string) {
    await this.dbRef.folder.delete(id)
    await this.dbRef.file.delete(id)
  }

  bulkDelete(keys: string[]) {
    this.dbRef.file.bulkDelete(keys)
  }

  async get(id: string): Promise<TFile> {
    const file = await this.dbRef.file.get(id)
    return file as TFile
  }

  getFile(id: string): TFile {
    return useLiveQuery(this.dbRef.file, () => this.dbRef.file.get(id), [id])
  }

  getList(): TFile[] {
    return useLiveQuery(this.dbRef.file, () => this.dbRef.file.toArray(), []) || []
  }
}
