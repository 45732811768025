exports.components = {
  "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-academic-papers-mdx": () => import("./../../../src/components/mdx/CategoryPage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/categories/academic_papers.mdx" /* webpackChunkName: "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-academic-papers-mdx" */),
  "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-book-mdx": () => import("./../../../src/components/mdx/CategoryPage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/categories/book.mdx" /* webpackChunkName: "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-book-mdx" */),
  "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-calendar-mdx": () => import("./../../../src/components/mdx/CategoryPage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/categories/calendar.mdx" /* webpackChunkName: "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-calendar-mdx" */),
  "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-cv-mdx": () => import("./../../../src/components/mdx/CategoryPage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/categories/cv.mdx" /* webpackChunkName: "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-cv-mdx" */),
  "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-letters-correspondence-mdx": () => import("./../../../src/components/mdx/CategoryPage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/categories/letters_correspondence.mdx" /* webpackChunkName: "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-letters-correspondence-mdx" */),
  "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-newsletters-mdx": () => import("./../../../src/components/mdx/CategoryPage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/categories/newsletters.mdx" /* webpackChunkName: "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-newsletters-mdx" */),
  "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-posters-mdx": () => import("./../../../src/components/mdx/CategoryPage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/categories/posters.mdx" /* webpackChunkName: "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-posters-mdx" */),
  "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-presentations-mdx": () => import("./../../../src/components/mdx/CategoryPage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/categories/presentations.mdx" /* webpackChunkName: "component---src-components-mdx-category-page-tsx-content-file-path-src-mdx-templates-categories-presentations-mdx" */),
  "component---src-components-mdx-template-page-tsx-content-file-path-src-mdx-templates-cv-classic-1-mdx": () => import("./../../../src/components/mdx/TemplatePage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/cv_classic_1.mdx" /* webpackChunkName: "component---src-components-mdx-template-page-tsx-content-file-path-src-mdx-templates-cv-classic-1-mdx" */),
  "component---src-components-mdx-template-page-tsx-content-file-path-src-mdx-templates-elsevier-mdx": () => import("./../../../src/components/mdx/TemplatePage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/elsevier.mdx" /* webpackChunkName: "component---src-components-mdx-template-page-tsx-content-file-path-src-mdx-templates-elsevier-mdx" */),
  "component---src-components-mdx-template-page-tsx-content-file-path-src-mdx-templates-ieee-con-mdx": () => import("./../../../src/components/mdx/TemplatePage.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/templates/ieee_con.mdx" /* webpackChunkName: "component---src-components-mdx-template-page-tsx-content-file-path-src-mdx-templates-ieee-con-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-formatter-tsx": () => import("./../../../src/pages/formatter.tsx" /* webpackChunkName: "component---src-pages-formatter-tsx" */),
  "component---src-pages-graph-tsx": () => import("./../../../src/pages/graph.tsx" /* webpackChunkName: "component---src-pages-graph-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-html-tsx": () => import("./../../../src/pages/html.tsx" /* webpackChunkName: "component---src-pages-html-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-list-tsx": () => import("./../../../src/pages/list.tsx" /* webpackChunkName: "component---src-pages-list-tsx" */),
  "component---src-pages-pdf-tsx": () => import("./../../../src/pages/pdf.tsx" /* webpackChunkName: "component---src-pages-pdf-tsx" */),
  "component---src-pages-table-tsx": () => import("./../../../src/pages/table.tsx" /* webpackChunkName: "component---src-pages-table-tsx" */),
  "component---src-pages-templates-tsx": () => import("./../../../src/pages/templates.tsx" /* webpackChunkName: "component---src-pages-templates-tsx" */)
}

