interface Config {
  IS_DEVELOPMENT: boolean
  DB_VERSION: number
  MAX_FILE_SIZE: number
  MAX_TEXLIVE_MEMORY_USE: number
  DELAY_OF_FILE_CONTENT_SAVING: number
  DELAY_OF_COMPILING_LATEX: number
  DB_NAME: string
  DOMAIN: string
  SUPPORTED_FILE_FORMATS: string[]
  SUPPORTED_TEXT_FILE_FORMATS: string[]
  LATEX_STYLE_HASH_SOURCE: string
  LATEX_SERVICE_URL: string
  TEMPLATES_CDN_URL: string
}

const IS_DEVELOPMENT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"

const config = {
  IS_DEVELOPMENT,
  DB_VERSION: 2,
  DOMAIN: IS_DEVELOPMENT
    ? "http://localhost:8000"
    : "https://latex-editor.pages.dev",
  MAX_FILE_SIZE: 25431761,
  MAX_TEXLIVE_MEMORY_USE: 80 * 1024 * 1024,
  DELAY_OF_FILE_CONTENT_SAVING: 800,
  DELAY_OF_COMPILING_LATEX: 500,
  DB_NAME: "LaTexEditor",
  SUPPORTED_FILE_FORMATS: ["tex", "bib", "jpg", "jpeg", "png", "gif", "tiff", "pdf"],
  SUPPORTED_TEXT_FILE_FORMATS: ["tex", "bib"],
  LATEX_STYLE_HASH_SOURCE: "91fbca02-b274-41d1-9ddf-4a2904b89f36",
  LATEX_SERVICE_URL: IS_DEVELOPMENT
    ? "https://ahmadsos5009.github.io/latex-live-js/"
    : "https://ahmadsos5009.github.io/latex-live-js/",
  TEMPLATES_CDN_URL:
    "https://raw.githubusercontent.com/ahmadsos5009/latex-templates/main",
}

export default config as Config
