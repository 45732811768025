import { Cell } from "@antv/x6"

const POLYGON = "custom-polygon"
const RECT = "custom-rect"
const ROUNDED_REACT = "custom-rect-rounded"
const RHOMBUS = "custom-rhombus"

const tools = [
  {
    name: "button-remove",
    args: {
      x: "90%",
      y: "10%",
    },
  },
]

const start: Partial<Cell.Metadata> = {
  width: 100,
  height: 40,
  shape: ROUNDED_REACT,
  tools,
  attrs: {
    body: {
      fill: "#ffb3b2",
      stroke: "#000000",
      strokeWidth: 1,
      rx: "10",
      ry: "10",
    },
    image: {
      event: "node:delete",
      xlinkHref: "trash.png",
      width: 20,
      height: 20,
    },
    label: {
      text: "Start",
      fill: "#333333",
      fontSize: 13,
      textWrap: {
        width: 80,
        height: 32,
      },
    },
  },
}

const input: Partial<Cell.Metadata> = {
  width: 110,
  height: 36,
  shape: RHOMBUS,
  tools,
  attrs: {
    body: {
      fill: "#afb3ff",
      stroke: "#000000",
      strokeWidth: 1,
      refPoints: "10,0 40,0 30,20 0,20",
    },
    label: {
      text: "Input",
      fill: "#333333",
      fontSize: 13,
      textWrap: {
        width: 88,
        height: 29,
      },
    },
  },
}

const process: Partial<Cell.Metadata> = {
  width: 100,
  height: 40,
  shape: RECT,
  tools,
  attrs: {
    body: {
      fill: "#ffd8b0",
      stroke: "#000000",
      strokeWidth: 1,
    },
    label: {
      text: "Process",
      fill: "#333333",
      fontSize: 13,
      textWrap: {
        width: 80,
        height: 32,
      },
    },
  },
}

const decision: Partial<Cell.Metadata> = {
  width: 100,
  height: 60,
  shape: POLYGON,
  tools: {
    name: "button-remove",
    args: {
      x: "75%",
      y: "28%",
    },
  },
  attrs: {
    body: {
      fill: "#b3feae",
      stroke: "#000000",
      strokeWidth: 1,
      refPoints: "0,10 10,0 20,10 10,20",
    },
    label: {
      text: "Decision",
      fill: "#333333",
      fontSize: 13,
      textWrap: {
        width: 80,
        height: 48,
      },
    },
  },
}

export { start, input, process, decision, RECT, ROUNDED_REACT, RHOMBUS, POLYGON }
