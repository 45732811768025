import useLiveQuery from "../hooks/use-live-query"
import { DexieDao } from "./IDao"
import { TProject } from "./types"

export default class ProjectDao extends DexieDao<TProject> {
  add(project: TProject): void {
    this.dbRef.project.add(project)
  }

  edit(id: string, project: TProject): void {
    this.dbRef.project.update(id, project)
  }

  async delete(id: string) {
    const foldersDB = await this.dbRef.folder.where("projectId").equals(id)
    const files = (await foldersDB.toArray()).filter(({ type }) => type === "file")

    await foldersDB.delete()
    await this.dbRef.file.bulkDelete(files.map(({ id: fileId }) => fileId))
    await this.dbRef.project.delete(id)
  }

  async get(id: string): Promise<TProject> {
    const project = await this.dbRef.project.get(id)
    return project as TProject
  }

  getList(): TProject[] {
    return (
      useLiveQuery(this.dbRef.project, () => this.dbRef.project.toArray(), []) || []
    )
  }
}
