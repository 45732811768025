import { Dexie, Table } from "dexie"

export interface TProject {
  id: string
  name: string
}

export interface TFolder {
  id: string
  type: "folder" | "file"
  name: string
  projectId: string
  parentId?: string
}

export type FileContent = string | ArrayBuffer | null | undefined

export type ImageType = "eps" | "pdf" | "png" | "jpg" | "jpeg"

export type ContentType = "tex" | ("cls" & ImageType)

export interface TFile {
  id: string
  contentType: ContentType
  content?: FileContent
}

export type GraphType = "flowchart" | "logic_gate"

export type LogicGateNode =
  | "OR"
  | "NOR"
  | "AND"
  | "NAND"
  | "XOR"
  | "XNOR"
  | "Buffer"
  | "NOT"

export type GraphNode =
  | "start"
  | "input"
  | "process"
  | "decision"
  | "arrow"
  | LogicGateNode

export interface TGraphStyle {
  id: string
  type: GraphType
  node: GraphNode
  style: unknown
}

export type DBSchema = {
  project: Table<TProject>
  folder: Table<TFolder>
  file: Table<TFile>
  graphStyle: Table<TGraphStyle>
} & Dexie

export const schema = {
  project: "id, name",
  folder: "id, type, name, projectId, parentId",
  file: "id, content, contentType",
  graph: "id, type, name, graph",
  graphStyle: "id, type, node, style",
  editorDocument: "id, type, name, document",
  template: "id, type, category, title, description, content",
}
